import { Typography } from '@material-tailwind/react'
import {FaPlay} from 'react-icons/fa'
import ServiceBanner from './serviceBanner'
export default function HomeBanner () {
  return (
    <>
    <div className='relative py-48 bg-center max-xl:px-6 xl:py-60 bg-home-bg after:absolute after:w-full after:h-full after:top-0 after:left-0 after:bg-black/30 after:z-0'>
      <div className='relative z-20 flex max-w-6xl mx-auto '>
        <div className='xl:w-1/2 '>
          <Typography
            as='h1'
            variant='h1'
            className='flex flex-col space-y-1 text-2xl font-bold text-white md:space-y-3 md:text-6xl font-quicSand'
          >
            <p className=''>Reach Your Hand</p>
            <div className='relative w-fit'>
              To Welfare
              <p className='absolute left-0 w-0 h-1 rounded-full animate-line -bottom-1 bg-main-orange'></p>
            </div>
            <p>Animals Out There</p>
          </Typography>
          <Typography as='p' className='mt-6 text-lg font-normal text-white opacity-0 animate-slide-in-right font-quicSand'>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi.
          </Typography>
          <div className='flex mt-12 sm:items-center max-sm:space-y-10 sm:space-x-4 max-sm:flex-col'>
          <div className=''>
              <a
                href=''
                className='px-10 py-5 text-base font-bold text-white transition-all duration-300 rounded-xl font-quicSand bg-main-orange hover:text-main-orange hover:bg-[#FFECE6]'
              >
                Get Started
              </a>
            </div>
            <div className='flex items-center space-x-4'>
                <div className='flex items-center justify-center w-16 h-16 text-white transition-all duration-300 border rounded-full cursor-pointer hover:text-main-orange hover:bg-white'>
                    <FaPlay />
                </div>
                <div className='font-bold text-white font-quicSand'>Watch Video</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ServiceBanner /></>
  )
}
