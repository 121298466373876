import { Link } from "react-router-dom";

export default function ServiceCard(props){
    return(
        <>
            <div className="sm:w-1/2 max-lg:mt-4 lg:w-1/3 flex flex-col space-y-3 group bg-white p-6 shadow-[0px_3px_15px_0px_rgba(0,0,0,0.1)]">
                <div className="transition-all duration-300 group-hover:-translate-y-3">
                    <img src={props.image} className="w-10 h-10" alt="" />
                </div>
                <div className="text-[#454647] font-quicSand text-xl font-bold">
                    {props.title}
                </div>
                <div className="text-[#999B9C] font-quicSand text-base font-normal">
                    {props.description}
                </div>
                <div>
                    <Link to={`${props.href}`} className="transition-all duration-300 text-main-orange group-hover:text-[#1C1E29]">
                        Read More
                    </Link>
                </div>
            </div>
        </>
    )
}