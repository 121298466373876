import { Route, Routes } from 'react-router-dom'
import Home from './pages/home'
import Header from './pages/layouts/header'

function App () {
  return (
    <>
      <Header />
      <Routes>
        <Route path='' element={<Home />} />

      </Routes>
    </>
  )
}

export default App
