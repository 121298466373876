import { FaFacebookF, FaInstagram, FaWhatsapp, FaYoutube } from 'react-icons/fa'
export default function Social (props) {
  const list = [
    {
      icon: <FaFacebookF />,
      href: ''
    },
    {
      icon: <FaInstagram />,
      href: ''
    },
    {
      icon: <FaWhatsapp />,
      href: ''
    },
    {
      icon: <FaYoutube />,
      href: ''
    }
  ]
  return (
    <>
      <div className='border-t-[1px] flex border-t-[#EAEAEA] space-x-2 pt-8'>
        {list &&
          list.map((item, index) => {
            return (
              <a
              key={index}
                href={item.href}
                className='flex items-center justify-center transition-all duration-300 rounded-lg w-11 h-11 bg-main-orange hover:bg-[#FFECE6] hover:text-main-orange hover:scale-90'
              >
                {item.icon}
              </a>
            )
          })}
      </div>
    </>
  )
}
