import React from 'react'
import {
  Navbar,
  Collapse,
  Typography,
  IconButton,
  Button
} from '@material-tailwind/react'
import { AiOutlineClose } from 'react-icons/ai'
import Logo from '../../assets/img/Logo.png'
import LogoBlack from '../../assets/img/LogoBlack.png'
import emailImg from '../../assets/img/icons/gmail.png'
import telImg from '../../assets/img/icons/telephone.png'
import locImg from '../../assets/img/icons/location.png'
import menuImg from '../../assets/img/menu.png'
import { Link } from 'react-scroll'
import Location from '../components/location'
import Social from '../components/social'
import Subscribe from '../components/subscribe'
export default function Header () {
  const [openNav, setOpenNav] = React.useState(false)
  const [openSlideNav, setOpenSlideNav] = React.useState(false)
  React.useEffect(() => {
    window.addEventListener(
      'resize',
      () => window.innerWidth >= 960 && setOpenNav(false)
    )
  }, [])

  const contactList = [
    {
      image: emailImg,
      title: 'Email Address',
      desc: 'veterna@support.com'
    },
    {
      image: telImg,
      title: 'Phone Number',
      desc: '(+62) 8152 254 239'
    },
    {
      image: locImg,
      title: 'Our Location',
      desc: 'Kuta, Bali 80361'
    }
  ]
  const navList = (
    <ul className='flex flex-col gap-2 mt-2 mb-4 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6'>
      <Typography
        as='li'
        variant='small'
        color='blue-gray'
        className='p-1 font-normal'
      >
        <Link
          to=''
          activeClass='active'
          spy={true}
          smooth={true}
          offset={50}
          duration={500}
          className='flex items-center font-semibold text-[#454647] font-quicSand text-[15px] transition-all duration-300 cursor-pointer hover:text-main-orange'
        >
          Home
        </Link>
      </Typography>
      <Typography
        as='li'
        variant='small'
        color='blue-gray'
        className='p-1 font-normal'
      >
        <Link
          to=''
          activeClass='active'
          spy={true}
          smooth={true}
          offset={50}
          duration={500}
          className='flex items-center font-semibold text-[#454647] font-quicSand text-[15px] transition-all duration-300 cursor-pointer hover:text-main-orange'
        >
          About
        </Link>
      </Typography>
      <Typography
        as='li'
        variant='small'
        color='blue-gray'
        className='p-1 font-normal'
      >
        <Link
          to=''
          activeClass='active'
          spy={true}
          smooth={true}
          offset={50}
          duration={500}
          className='flex items-center font-semibold text-[#454647] font-quicSand text-[15px] transition-all duration-300 cursor-pointer hover:text-main-orange'
        >
          Services
        </Link>
      </Typography>
      <Typography
        as='li'
        variant='small'
        color='blue-gray'
        className='p-1 font-normal'
      >
        <Link
          to=''
          activeClass='active'
          spy={true}
          smooth={true}
          offset={50}
          duration={500}
          className='flex items-center font-semibold text-[#454647] font-quicSand text-[15px] transition-all duration-300 cursor-pointer hover:text-main-orange'
        >
          Features
        </Link>
      </Typography>
      <Typography
        as='li'
        variant='small'
        color='blue-gray'
        className='p-1 font-normal'
      >
        <Link
          to=''
          activeClass='active'
          spy={true}
          smooth={true}
          offset={50}
          duration={500}
          className='flex items-center font-semibold text-[#454647] font-quicSand text-[15px] transition-all duration-300 cursor-pointer hover:text-main-orange'
        >
          Blog
        </Link>
      </Typography>
      <Typography
        as='li'
        variant='small'
        color='blue-gray'
        className='p-1 font-normal'
      >
        <Link
          to=''
          activeClass='active'
          spy={true}
          smooth={true}
          offset={50}
          duration={500}
          className='flex items-center font-semibold text-[#454647] font-quicSand text-[15px] transition-all duration-300 cursor-pointer hover:text-main-orange'
        >
          Contact
        </Link>
      </Typography>
    </ul>
  )

  return (
    <>
      <div
        className={
          openSlideNav
            ? 'lg:w-96 h-full fixed top-0 left-0 bg-white overflow-hidden transition-all duration-700 z-50'
            : 'lg:w-0 h-full fixed top-0 left-0 bg-white transition-all overflow-hidden duration-700 z-50'
        }
      >
        <div className='hidden text-white lg:block'>
          <div className='flex flex-col h-full px-8 py-8'>
            <div className='flex justify-end'>
              <AiOutlineClose
                className='text-3xl cursor-pointer text-main-orange'
                onClick={() => {
                  setOpenSlideNav(!openSlideNav)
                }}
              />
            </div>
            <div className='flex flex-col space-y-20 overflow-auto'>
              <div>
                <img src={LogoBlack} className='w-44' alt='' />
              </div>
              <div className='text-[#999B9C] font-quicSand font-normal text-base'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                vel risus imperdiet, gravida justo eu.
              </div>
              <div>
                <Location title={'Our Location'} color={'text-[#454647]'} />
              </div>
              <div>
                <Social />
              </div>
              <div>
                <div className='text-[#454647] font-quicSand text-2xl font-bold'>
                  Subscribe Newsletter
                </div>
                <Subscribe />
              </div>
            </div>
          </div>
        </div>
        <div className='block text-white lg:hidden'></div>
      </div>
      <div
        className={
          openSlideNav
            ? 'max-lg:w-96 h-full fixed top-0 left-0 bg-white overflow-hidden transition-all duration-700 z-50  lg:hidden'
            : 'max-lg:w-0 h-full fixed top-0 left-0 bg-white overflow-hidden transition-all duration-700 z-50 lg:hidden'
        }
      >
        <div className='px-8'>
          <div className='flex items-center justify-between mt-24'>
            <div>
              <img src={LogoBlack} className='w-44' alt='' />
            </div>
            <div>
              <AiOutlineClose
                className='text-2xl cursor-pointer text-main-orange'
                onClick={() => {
                  setOpenSlideNav(!openSlideNav)
                }}
              />
            </div>
          </div>
          <div className='mt-12'>
            <ul className='flex flex-col gap-2 mt-2 mb-4 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6'>
              <Typography
                as='li'
                variant='small'
                color='blue-gray'
                className='p-1 font-normal'
              >
                <Link
                  to=''
                  activeClass='active'
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                  className='flex items-center font-semibold text-[#454647] font-quicSand text-[15px] transition-all duration-300 cursor-pointer hover:text-main-orange'
                >
                  Home
                </Link>
              </Typography>
              <Typography
                as='li'
                variant='small'
                color='blue-gray'
                className='p-1 font-normal'
              >
                <Link
                  to=''
                  activeClass='active'
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                  className='flex items-center font-semibold text-[#454647] font-quicSand text-[15px] transition-all duration-300 cursor-pointer hover:text-main-orange'
                >
                  About
                </Link>
              </Typography>
              <Typography
                as='li'
                variant='small'
                color='blue-gray'
                className='p-1 font-normal'
              >
                <Link
                  to=''
                  activeClass='active'
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                  className='flex items-center font-semibold text-[#454647] font-quicSand text-[15px] transition-all duration-300 cursor-pointer hover:text-main-orange'
                >
                  Services
                </Link>
              </Typography>
              <Typography
                as='li'
                variant='small'
                color='blue-gray'
                className='p-1 font-normal'
              >
                <Link
                  to=''
                  activeClass='active'
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                  className='flex items-center font-semibold text-[#454647] font-quicSand text-[15px] transition-all duration-300 cursor-pointer hover:text-main-orange'
                >
                  Features
                </Link>
              </Typography>
              <Typography
                as='li'
                variant='small'
                color='blue-gray'
                className='p-1 font-normal'
              >
                <Link
                  to=''
                  activeClass='active'
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                  className='flex items-center font-semibold text-[#454647] font-quicSand text-[15px] transition-all duration-300 cursor-pointer hover:text-main-orange'
                >
                  Blog
                </Link>
              </Typography>
              <Typography
                as='li'
                variant='small'
                color='blue-gray'
                className='p-1 font-normal'
              >
                <Link
                  to=''
                  activeClass='active'
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                  className='flex items-center font-semibold text-[#454647] font-quicSand text-[15px] transition-all duration-300 cursor-pointer hover:text-main-orange'
                >
                  Contact
                </Link>
              </Typography>
            </ul>
          </div>
        </div>
      </div>
      <div className='w-full bg-[#1C1E29] pt-8 pb-12 max-xl:px-6'>
        <div className='flex justify-between max-w-6xl mx-auto max-lg:flex-col max-lg:items-center max-lg:space-y-6'>
          <div>
            <img src={Logo} className='w-52' alt='' />
          </div>
          <div className='flex space-x-8 max-md:hidden'>
            {contactList &&
              contactList.map((item, index) => {
                return (
                  <div className='flex items-start space-x-4' key={index}>
                    <div>
                      <img src={item.image} alt='' />
                    </div>
                    <div className='text-white font-quicSand'>
                      <div className='text-sm font-medium text-[#BCBCBC]'>
                        {item.title}
                      </div>
                      <div className='text-[15px] font-semibold text-white font-quicSand'>
                        {item.desc}
                      </div>
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
      </div>
      <div className='absolute z-20 w-full top-28 md:top-48 lg:top-28 max-xl:px-6'>
        <Navbar
          className='max-w-6xl px-4 py-6 mx-auto bg-white shadow-none lg:px-8 lg:py-8'
          id='Navbar'
        >
          <div className='container flex items-center justify-between mx-auto text-blue-gray-900'>
            <img
              src={menuImg}
              alt=''
              className='cursor-pointer'
              onClick={() => {
                setOpenSlideNav(!openSlideNav)
              }}
            />

            <div className='hidden lg:block'>{navList}</div>
            <div className=''>
              <a
                href=''
                className='px-10 py-5 text-base font-bold text-white transition-all duration-300 rounded-xl font-quicSand bg-main-orange hover:text-main-orange hover:bg-[#FFECE6]'
              >
                Donate Now
              </a>
            </div>
          </div>
          <Collapse open={openNav}>
            <div className='container mx-auto'>
              {navList}
              <Button variant='gradient' size='sm' fullWidth className='mb-2'>
                <span>Buy Now</span>
              </Button>
            </div>
          </Collapse>
        </Navbar>
      </div>
    </>
  )
}
