import { FaMapMarkerAlt, FaEnvelope, FaPhone, FaClock } from 'react-icons/fa'
import { List, ListItem, Card } from '@material-tailwind/react'

export default function Location (props) {
  const list = [
    {
      icon: <FaMapMarkerAlt />,
      text: 'Jl. Sunset Road No.815, DPS, Bali'
    },
    {
      icon: <FaEnvelope />,
      text: 'veterna@support.com'
    },
    {
      icon: <FaPhone />,
      text: '(+62) 8152 254 239'
    },
    {
      icon: <FaClock />,
      text: 'Mon-Sat : 8:00 AM to 07:00 PM'
    }
  ]
  return (
    <>
      <div>
        <div className={`${props.color} font-quicSand text-2xl font-bold`}>
          {props.title}
        </div>
        <Card className='w-full rounded-none shadow-none bg-none'>
          <List className='p-0'>
            {list &&
              list.map((item, index) => {
                return (
                  <ListItem className='flex p-0 space-x-4' key={index}>
                    <span className='text-xs text-main-orange'>
                      {item.icon}
                    </span>
                    <span className='text-[#454647] text-base font-quicSand font-normal'>
                      {item.text}
                    </span>
                  </ListItem>
                )
              })}
          </List>
        </Card>
      </div>
    </>
  )
}
