import { FaTelegramPlane } from 'react-icons/fa'
export default function Subscribe () {
  return (
    <>
      <div className='flex space-x-4'>
        <input
          type='text'
          className='bg-[#F9F9F9] w-4/5 box-border placeholder:text-[#454647] px-4 py-4 rounded-xl'
          placeholder='Your Email'
        />
        <button className='text-white  bg-main-orange h-[53px] flex justify-center items-center w-1/5 rounded-xl'>
          <FaTelegramPlane />
        </button>
      </div>
    </>
  )
}
