import ServiceCard from './serviceCard'

export default function ServiceBanner () {
  const list = [
    {
      image:
        'https://templatekit.jegtheme.com/veterna/wp-content/uploads/sites/198/2021/11/Asset-4-REZ364R.png',
      title: 'Pet Donations',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor',
      href: ''
    },
    {
      image:
        'https://templatekit.jegtheme.com/veterna/wp-content/uploads/sites/198/2021/11/Asset-2-REZ364R.png',
      title: 'Animal Rescue',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor',
      href: ''
    },
    {
      image:
        'https://templatekit.jegtheme.com/veterna/wp-content/uploads/sites/198/2021/11/Asset-3-REZ364R.png',
      title: 'Adopt Animal',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor',
      href: ''
    }
  ]
  return (
    <div className='relative z-20 w-full bottom-24 max-xl:px-6'>
      <div className='flex max-w-6xl mx-auto space-x-6'>
        <div className='flex w-full max-lg:flex-wrap  lg:w-3/4 overflow-hidden rounded-xl shadow-[0px_3px_15px_0px_rgba(0,0,0,0.1)]'>
          {list &&
            list.map((item, index) => {
              return (
                <ServiceCard
                  image={item.image}
                  title={item.title}
                  description={item.description}
                  href={item.href}
                  key={index}
                />
              )
            })}
             <div className='relative z-20 p-6 mt-4 bg-no-repeat sm:w-1/2 lg:hidden bg-main-orange rounded-r-xl'>
          <div className='absolute z-0 w-16 h-16 opacity-50 right-4'>
            <img
              src='https://templatekit.jegtheme.com/veterna/wp-content/uploads/sites/198/2021/11/feet1-44ZSNX.png'
              alt=''
            />
          </div>
          <div className='flex flex-col h-full justify-evenly'>
            <div>Explore More</div>
            <div>
              Lorem ipsum dolor sit amet, consectetur adipi scing elit. Ut elit
              tellus, luctus nec ullamcorper.
            </div>
            <div className='mt-4'>
            <a
                href=''
                className='px-10 py-5 text-base font-bold  transition-all duration-300 rounded-xl font-quicSand  text-main-orange bg-[#FFECE6]'
              >
                Get Started
              </a>
            </div>
          </div>
        </div>
        </div>
        <div className='relative z-20 w-1/4 p-6 bg-no-repeat border max-lg:hidden bg-main-orange rounded-xl'>
          <div className='absolute z-0 w-16 h-16 opacity-50 right-4'>
            <img
              src='https://templatekit.jegtheme.com/veterna/wp-content/uploads/sites/198/2021/11/feet1-44ZSNX.png'
              alt=''
            />
          </div>
          <div className='flex flex-col h-full justify-evenly'>
            <div>Explore More</div>
            <div>
              Lorem ipsum dolor sit amet, consectetur adipi scing elit. Ut elit
              tellus, luctus nec ullamcorper.
            </div>
            <div className='mt-4'>
            <a
                href=''
                className='px-10 py-5 text-base font-bold  transition-all duration-300 rounded-xl font-quicSand  text-main-orange bg-[#FFECE6]'
              >
                Get Started
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
